

const FREE_SESSION_TIME = 80;

$(document).ready(function () {
		// if (typeof moment === "undefined") {
		// 		const momentScript = document.createElement("script");
		// 		momentScript.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js");
		// 		momentScript.setAttribute("integrity", "sha512-qTXRIMyZIFb8iQcfjXWCO8+M5Tbc38Qi5WzdPOYZHIlZpzBHG3L3by84BBBOiRGiEb7KKtAOAs5qYdUiZiQNNQ==");
		// 		momentScript.setAttribute("crossorigin", "anonymous");
		// 		momentScript.setAttribute("async", "async");
		//
		// 		document.body.appendChild(momentScript);
		//
		// 		momentScript.addEventListener("load", () => {
		// 				checkAndProceedFreeSessionUser();
		// 		}, false);
		// } else {
		// 		checkAndProceedFreeSessionUser();
		// }


		$('#loginModal').on('hidden.bs.modal', function () {
				checkAndProceedFreeSessionUser();
		});

		$('#logoutMenuTopHeader').on('click', () => {
				checkAndProceedFreeSessionUser();
		});
		
});

const checkAndProceedFreeSessionUser = () => {
		if (typeof user !== "undefined" && user) {
				$('.blurEffectSchool>div>div>*').css({
					'filter': 'blur(0)',
					'pointer-events': 'auto',
					'cursor': 'unset',
					'user-select': 'initial'
				});
		} else {
				freeSessionUser();
		}
}

const freeSessionUser = () => {

		let sessionID = localStorage.getItem('sessionInit');
		if (sessionID && moment().diff(moment(sessionID), 'm') > FREE_SESSION_TIME) {
				localStorage.removeItem('usedFreeSession');
				localStorage.removeItem('sessionInit');
				localStorage.removeItem('freeSessionTill');
		}

		if (!!!localStorage.getItem('usedFreeSession')) {

				if (!!!localStorage.getItem('sessionInit')) {
						localStorage.setItem('sessionInit', moment());
						localStorage.setItem('freeSessionTill', moment().add(FREE_SESSION_TIME, 'seconds'));
				}

				let sessionInit = localStorage.getItem('sessionInit');
				let sessionTill = localStorage.getItem('freeSessionTill');

				if (!!sessionInit && !!sessionTill) {

						if (typeof hideBlurContent === 'function') {
								hideBlurContent();
						}
						if (typeof withoutBlurLoginDesign === 'function') {
								withoutBlurLoginDesign($(window).scrollTop());
						}

						let sessionTimerElm = document.createElement('div');
						sessionTimerElm.id = 'sessionTimer';
						sessionTimerElm.className = 'user-session-timer text-center';


						$('body').append(sessionTimerElm);

						let sessionTimerSelector = $('#sessionTimer');

						sessionTimerSelector.css({
								'position': 'fixed',
								'bottom': '1rem',
								'left': '1rem',
								'width': '10rem',
								'height': '2rem',
								'background-color': 'rgb(0 0 0 / 75%)',
								'color': 'rgb(255 255 255)',
								'border-radius': '.25rem',
								'line-height': '2rem',
								'font-weight': '500',
								'display': 'none',
								'z-index': '9'
						});

						sessionInit = moment(sessionInit);
						sessionTill = moment(sessionTill);

						let timeEla = sessionTill.diff(sessionInit);
						let duration = moment.duration(timeEla, 'ms');

						let interval = 1000;

						let timerIns = setInterval(function () {
								duration = moment.duration(duration - interval, 'ms');
								sessionTimerSelector.css({'display': 'none'});
								sessionTimerSelector.text(`${duration.hours()} : ${duration.minutes()} : ${duration.seconds()}`);
								localStorage.setItem('sessionInit', moment());
								if (typeof user !== "undefined" && user) {
										clearInterval(timerIns);
										sessionTimerSelector.css({'display': 'none'});
										return;
								}
								if (duration.minutes() === 0 && duration.seconds() === 0) {
										localStorage.setItem('usedFreeSession', '1');
										clearInterval(timerIns);
										sessionTimerSelector.css({'display': 'none'});
										if (typeof pageType !== "undefined" && pageType === 'DETAILS-PAGE' && typeof onScrollModalShow === 'function') {
												onScrollModalShow();
										}
								}
						}, interval);
				}
		} else {
				if (typeof pageType !== "undefined" && pageType === 'DETAILS-PAGE' && typeof onScrollModalShow === 'function') {
						onScrollModalShow();
				}
		}
}
