const loadingBarElm = $('.loading-bar');

const hide_loader = () => loadingBarElm.hide();

const show_loader = () => loadingBarElm.show();

const loader_school = (typeSchool, hide = false) => {
		let loaderElem = `#${typeSchool}SchoolLoader`; /* loader element id */
		if (hide) $(loaderElem).hide(); else $(loaderElem).show();
}

$(document).ready(() => {
		let navbarElm = $(".navbar");
		navbarElm.toggleClass("navbar-sticky-function");
		navbarElm.toggleClass("navbar-sticky");
});

const calculateDistance = (from, to) => {
		if (from && to) {
				let fromLat = from.lat;
				let fromLng = from.lng;
				let toLat = to.lat;
				let toLng = to.lng;

				let fromRadLat = Math.PI * fromLat / 180;
				let toRadLat = Math.PI * toLat / 180;
				let theta = fromLng - toLng;
				let radTheta = Math.PI * theta / 180;
				let dist = Math.sin(fromRadLat) * Math.sin(toRadLat) + Math.cos(fromRadLat) * Math.cos(toRadLat) * Math.cos(radTheta);

				dist = Math.acos(dist);
				dist = dist * 180 / Math.PI;
				dist = dist * 60 * 1.1515;
				dist = dist * 1.609344;
				dist = dist.toFixed(2);
				dist = dist + " kms";
				return dist
		}
};

let distance_condition = (distance) => {
		let initialDist = distance;
		distance = distance.replace("[^\\d.]", "");
		distance = parseFloat(distance);
		if (distance > 500) {
				return `<span>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            More than 500 Km. 
            </span>`;
		} else {
				return `<span>
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            ${initialDist}
            </span>`;
		}
}

function slugify(string) {
		const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
		const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string.toString().toLowerCase()
				.replace(/\s+/g, '-') /* Replace spaces with -	*/
				.replace(p, c => b.charAt(a.indexOf(c))) /* Replace special characters	*/
				.replace(/&/g, '-and-') /* Replace & with 'and'	*/
				.replace(/[^\w\-]+/g, '') /* Remove all non-word characters	*/
				.replace(/\-\-+/g, '-') /* Replace multiple - with single -	*/
				.replace(/^-+/, '') /* Trim - from start of text	*/
				.replace(/-+$/, '') /* Trim - from end of text	*/
}

String.prototype.capitalize = function () {
		return this.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
};
