function headerMenuOptionsClickAnalytics(action) {
	gtag('event', {
		event_category: 'Header Menu',
		event_action: action,
		event_label: action
	});
}

function headerMenuOptionsClickAnalyticsWithOptionValue(action, value) {
	gtag('event', {
		event_category: 'Header Menu',
		event_action: action,
		event_label: action,
		value: value
	});
}

function HeaderSearchAnalytics(action) {
    let actionText = action.trim();
	gtag('event', {
		event_category: 'Header Search',
		event_action: actionText,
		event_label: actionText
	});
}


$(document).ready(function () {
    $('#dualHeader ul li').click(function () {
        let menuText = $(this).data('name');
        if (menuText === 'Home (current)') {
            menuText = 'Home';
        }
        headerMenuOptionsClickAnalytics(menuText);
    });

    $('.headertel').click(function () {
        headerMenuOptionsClickAnalytics('Call us');
    });

    $('#freeCounselling #callbackBtn').click(function () {
			gtag('event', {
				event_category: 'Request Callback',
				event_action: 'Request callback modal open',
				event_label: 'Request callback modal open'
			});
    });

    /*$('#assistantMenuTopHeader,#AssistantHeader').click(function () {
        headerMenuOptionsClickAnalytics('Smart Search');
    });*/

    $('#signInBtnTopHeader').click(function () {
        headerMenuOptionsClickAnalytics('Sign In');
    });

    $('#profileMenuTopHeader').click(function () {
        headerMenuOptionsClickAnalytics('Profile Menu');
    });

    $('#shortlistMenuTopHeader').click(function () {
        headerMenuOptionsClickAnalyticsWithOptionValue('Shortlist Menu', 1);
    });

    $('#logoutMenuTopHeader').click(function () {
        headerMenuOptionsClickAnalytics('Logout Menu');
    });

/*    $('#shortlistHeaderHeartIcon').click(function () {
        headerMenuOptionsClickAnalyticsWithOptionValue('Shortlist Menu', 2);
    })*/

});



