let school;
const pref_loc_data = {};
let location_type = 'CURRENT';
let justSubmitted = false;
/*let geocoder = new google.maps.Geocoder;*/
let initialLocation = {lat: latitude, lng: longitude, city: cityName};
let searchedCity;
// let locationType = ['PREF', 'INPUT', 'CURRENT'];

if (typeof pageType === 'undefined') pageType = ''; // Checking page type Undefined conditions

let inputPref = document.getElementById('prefSearchTextField');
let autocompletePref = new CustomAutoCompleteMaps(inputPref);

$(document).ready(function () {
		if (pageType && pageType === 'PARENT_ASSISTANT_PAGE') return false;

		school = {slat: initialLocation.lat, slon: initialLocation.lng};

		// google.maps.event.addListener(autocompletePref, 'place_changed', () => {
		//     let place = autocompletePref.getPlace();
		//     $('#prefLoclat').val(place.geometry.location.lat());
		//     $('#prefLoclng').val(place.geometry.location.lng());
		//     $('#userLocPref').val(place.geometry.location.lat());
		//     $('#userLongPref').val(place.geometry.location.lng());
		//     placeChangedDebugApiCallAnalytics();
		// });

		CustomAutoCompleteMaps.addListener(autocompletePref, 'place_changed', () => {
				let place = autocompletePref.getPlace();
				$('#prefLoclat').val(place.geometry.location.lat());
				$('#prefLoclng').val(place.geometry.location.lng());
				$('#userLocPref').val(place.geometry.location.lat());
				$('#userLongPref').val(place.geometry.location.lng());
				// placeChangedDebugApiCallAnalytics();
		});

		$('#pref_location_btn').text('Change Preferred Location');
		$('#pref_location_text').text('Preferred location ' + initialLocation.city);
		refresh_initial_location();

		jquery_validatea = true;

		set_jq_dflt();

});

function submitPrefLoc() {
		if (justSubmitted) return;
		justSubmitted = true;
		show_loader();
		let data = {
				place_name: $('#prefSearchTextField').val(),
				location_type: location_type,
				lat: $('#prefLoclat').val(),
				lng: $('#prefLoclng').val(),
				label: $('input[name=prefOpt]:checked').val()
		};
		if (DEBUG) console.log('submitting tracking data', data);
		/* if (pref_loc_data.id) {
				 // data.location_type = pref_loc_data.location_type;
				 $.post(`${HOST}/users/user_track/user_track_update.php`, data)
						 .done((response) => {
						 if (response) {
								 $('#wishlistModal').modal('hide');
								 $('#pref_location_error').text('');
								 getPrefLoc();
						 } else {
								 $('#pref_location_error').text("please try again failed to update Preferred location.");
						 }
				 }).always(()=>hide_loader());
		 } else {*/
		if (pageType && pageType === "Homepage") {
				data.location_type = "CURRENT";
		} else if (pageType === "search") {
				data.location_type = "INPUT";
		}
		$.post(`${HOST}/users/user_track/user_track_insert_or_update.php`, data) /* users/user_track/user_track_insert.php replaced */
				.done((response) => {
						if (response) {
								if (DEBUG) console.log('Final submit cron waiting for call');
								$('#wishlistModal').modal('hide');
								$('#pref_location_error').text('');
								getPrefLoc();
								//preSchoolCron(user.id);
						} else {
								$('#pref_location_error').text("please try again failed to set Preferred location.");
						}
						hide_loader();
				});
		// }
}

function getPrefLoc() {
		if (pageType && pageType === 'PARENT_ASSISTANT_PAGE') {
				pageType = '';
				return false;
		}
		if (user) {
				$.ajax({
						type: "GET",
						url: `${HOST}/users/user_track/user_track_get.php`,
						cache: false,
						headers: {
								'Cache-Control': 'no-cache, no-store, must-revalidate',
								'Pragma': 'no-cache',
								'Expires': '0'
						},
						success: (response) => {
								if (response) {
										$('#pref_location_btn').text('Update Preferred Location');
										// pref_loc_data = JSON.parse(response);   /* data already in json format no need to format */
										$('#prefSearchTextField').val(pref_loc_data.place_name);
										location_type = pref_loc_data.location_type;
										$('#prefLoclat').val(pref_loc_data.lat);
										$('#prefLoclng').val(pref_loc_data.lng);
										$('#userLocPref').val(pref_loc_data.lat);
										$('#userLongPref').val(pref_loc_data.lng);
										if (pref_loc_data.label) {
												$('input[name=prefOpt][value=' + pref_loc_data.label + ']').attr('checked', 'checked');
										}
										if ($('input[name=prefOpt]:checked').val() === "home") {
												$('#pref_location_text').html('Preferred location <b class="text-capitalize">' + $('input[name=prefOpt]:checked').val() + '</b>');
										} else {
												$('#pref_location_text').text('Preferred location ' + pref_loc_data.place_name);
										}
										loadWishlist();
								} else {
										refresh_initial_location();
										$('#prefSearchTextField').val(initialLocation.city);
										$('#prefLoclat').val(initialLocation.lat);
										$('#prefLoclng').val(initialLocation.lng);
										$('#userLocPref').val(pref_loc_data.lat); // used for shortlist
										$('#userLongPref').val(pref_loc_data.lng);
										if ((typeof (schoolType) != 'undefined' && ([3, 4, 6].includes(schoolType)) && pageType == 'detail') || pageType == 'PAID_COUNSELLING') {
												hiddenPreferredLocation();
										} else {
												$('#wishlistModal').modal('show');
										}
								}
						}
				});
		}
}

function getAddressFromUrl() {
		initialLocation.lat = $('#cityLat').val();
		initialLocation.lng = $('#cityLng').val();
		initialLocation.city = $('#formatted_address').val();

		if (initialLocation.lat === '' || initialLocation === '') {
				initialLocation.lat = slat;
				initialLocation.lng = slon;
				initialLocation.city = null;
		}
		return initialLocation;
}

function set_jq_dflt() {
		jQuery.validator.setDefaults({
				debug: true,
				success: "valid"
		});
}

var jquery_validatea = false;
var dditional_methods = false;
var additional_methods = document.createElement('script');

/*jquery_validate.onload = (() => {
		jquery_validatea = true;
		additional_methods.type = 'text/javascript';
		additional_methods.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.16.0/additional-methods.min.js';
		body.appendChild(additional_methods);
		if (jquery_validatea && dditional_methods) {
				set_jq_dflt();
		}

});*/
/*additional_methods.onload = (() => {
		dditional_methods = true;
		if (jquery_validatea && dditional_methods) {
				set_jq_dflt();
		}
});*/


function refresh_initial_location() {
		if (pref_loc_data.location_type && pref_loc_data.location_type === 'PREF') {
				initialLocation = {
						lat: pref_loc_data.lat,
						lng: pref_loc_data.lng,
						city: pref_loc_data.place_name
				}
		} else if (pageType === "search") {
				initialLocation = getAddressFromUrl();
				if (!initialLocation.city) {
					gtag('event', {
						event_category: 'debug_api_call',
						event_action: 'Preferred_search',
						event_label: 'Preferred_search'
					});
						if ($('#ip_cityname').val()) {
							gtag('event', {
								event_category: 'debug_api_call',
								event_action: 'Preferred_search_ip_city',
								event_label: 'Preferred_search_ip_city'
							});
								initialLocation.city = $('#ip_cityname').val();
						} else {
							gtag('event', {
								event_category: 'debug_api_call',
								event_action: 'Preferred_search_geo_coder_city',
								event_label: 'Preferred_search_geo_coder_city'
							});
								initialLocation.city = getLocationName(initialLocation);
						}
				}
		} else if (pageType && pageType == "HOMEPAGE") {
				if (!initialLocation.city) {
					gtag('event', {
						event_category: 'debug_api_call',
						event_action: 'Preferred_home',
						event_label: 'Preferred_home'
					});
						initialLocation.city = getLocationName(initialLocation);
				}
		} else if (school && pageType && pageType == "DETAILS-PAGE") {
				initialLocation = {
						lat: school.slat,
						lng: school.slon,
						city: schoolAddressCity // schoolAddressCity is in detail page
				}
		} else if (school) { // other pages case
				initialLocation = {
						lat: school.slat,
						lng: school.slon,
						city: cityName
				}
		}
		if (!initialLocation.city) {
			gtag('event', {
				event_category: 'debug_api_call',
				event_action: 'Preferred_all_other_cases',
				event_label: 'Preferred_all_other_cases'
			});
				initialLocation.city = getLocationName(initialLocation);
		}
}


$('#wishlistModal').on('shown.bs.modal', () => {
		refresh_initial_location();
});

$('#wishlistModal').on('hidden.bs.modal', () => {
		if (pref_loc_data.location_type && pref_loc_data.location_type === 'PREF') return;
		let place_name = $('#prefSearchTextField').val();
		let lat = $('#prefLoclat').val();
		let lng = $('#prefLoclng').val();
		if ((pageType == "search") || (pageType == "Homepage") || school) {
				$('#prefSearchTextField').val(initialLocation.city);
				location_type = location_type;
				$('#prefLoclat').val(initialLocation.lat);
				$('#prefLoclng').val(initialLocation.lng);
				submitPrefLoc();
		}
});

function hiddenPreferredLocation() {
		let place_name = $('#prefSearchTextField').val();
		let lat = $('#prefLoclat').val();
		let lng = $('#prefLoclng').val();
		if ((pageType == "search") || (pageType == "Homepage") || school || (pageType == 'PAID_COUNSELLING')) {
				$('#prefSearchTextField').val(initialLocation.city);
				location_type = location_type;
				$('#prefLoclat').val(initialLocation.lat);
				$('#prefLoclng').val(initialLocation.lng);
				submitPrefLoc();
		}
		//preSchoolCron(user.id);
}

function getLocationName(initiLocation) {
		let latlng = {lat: parseFloat(initiLocation.lat), lng: parseFloat(initiLocation.lng)};
		geocoder.geocode({'location': latlng}, function (results, status) {
				if (status === 'OK') {
						if (results[0]) {
								initiLocation.city = results[0].formatted_address;
								initialLocation.city = results[0].formatted_address;
								$('#prefSearchTextField').val(initialLocation.city);
								return initiLocation.city;
						} else {
								if (DEBUG) console.log('No results found');
								if (getLocationName(initialLocation)) {
										initialLocation.city = searchedCity;
								}
						}
				} else {
						if (DEBUG) console.log('Geocoder failed due to: ' + status);
						if (pageType == "search") {
								initialLocation.city = searchedCity;
						}
				}
		});
}


$('#prefLocForm').on('submit', (e) => {
		justSubmitted = false;
		let prefForm = $("#prefLocForm");
		prefForm.validate();
		e.preventDefault();
		if (prefForm.valid()) {
				location_type = 'PREF';
				submitPrefLoc();
				return true;
		} else {
				return false;
		}
});


const preSchoolCron = (userId) => {

		if (window.load) {
				if (DEBUG) console.log('Page Loading');
				return;
		}

		if (typeof pageType == 'undefined') {
				return;
		}
		/*if (pageType == 'DETAILS-PAGE' && schoolType == 2) {
				$.ajax({
						url: HOST + '/admin/lead_admin/crons/assign_phone_leads.php',
						type: 'POST',
						data: {userid: userId},
						success: function (data) {
								if (DEBUG) console.log(data);
						},
						error: function (data) {
								if (DEBUG) console.log(data);
						}
				})
		}*/
};
